var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"col-12 col-md-7"},[_c('CRow',[_c('CCol',{attrs:{"col":""}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Estado devolución de dinero")])]),_c('CCardBody',[_c('div',{staticClass:"form-group col-md-10"},[_c('label',{attrs:{"for":""}},[_vm._v("Seleccione una suscripción")]),_c('select',{staticClass:"form-control",on:{"change":function($event){return _vm.changeSuscription($event)}}},[_c('option',{attrs:{"value":""}},[_vm._v("--Seleccionar--")]),_vm._l((_vm.suscripciones),function(item,index){return _c('option',{key:item.id,domProps:{"value":index + 1}},[_vm._v(" "+_vm._s(item.requests)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-10"},[_c('label',{attrs:{"for":""}},[_vm._v("Seleccione una solicitud")]),_c('select',{staticClass:"form-control",on:{"change":function($event){return _vm.detalleSolicitudes($event)}}},[_c('option',{attrs:{"value":""}},[_vm._v("--Seleccionar--")]),_vm._l((_vm.arrListDevoluciones),function(item,index){return _c('option',{key:item.id,domProps:{"value":index + 1}},[_vm._v(" "+_vm._s(item)+" ")])})],2)]),_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.nameSuscription2)+" "),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"https://coreui.io/vue/docs/components/card-components","rel":"noreferrer noopener","target":"_blank"}})])]),_c('CCardBody',{staticClass:"p-4"},[(_vm.suscriptionSelected.name)?_c('div',{staticClass:"col-md-10 container__detalle"},[(
                      _vm.suscriptionSelected.monto != '' &&
                      _vm.suscriptionSelected.monto != null &&
                      _vm.suscriptionSelected.monto != undefined
                    )?_c('p',[_c('strong',[_vm._v("Importe aproximado: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.monto)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.banco != '' &&
                      _vm.suscriptionSelected.banco != null &&
                      _vm.suscriptionSelected.banco != undefined
                    )?_c('p',[_c('strong',[_vm._v("Banco: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.banco)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.nroCuenta != '' &&
                      _vm.suscriptionSelected.nroCuenta != null &&
                      _vm.suscriptionSelected.nroCuenta != undefined
                    )?_c('p',[_c('strong',[_vm._v("Nro. Cuenta: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.nroCuenta)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.message != '' &&
                      _vm.suscriptionSelected.message != null &&
                      _vm.suscriptionSelected.message != undefined
                    )?_c('p',[_c('strong',[_vm._v("Detalle : ")]),_vm._v(_vm._s(_vm.mensajeDetalle)+" ")]):_vm._e()]):_vm._e()])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"col-12 col-md-5 d-flex justify-content-center align-items-center"},[(_vm.flagImg == 1)?_c('img',{staticClass:"avatar__alicia-pq",attrs:{"src":require("../../assets/img/nueva_alicia_pages_all.png")}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }