<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.c-main {
  overflow: hidden;
}

.btn__modal {
  background: transparent;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid;
}
.btn__modal:hover {
  background: #000;
  color: #fff;
}

.avatar__alicia-pq {
  height: 55vh;
  margin-top: 20vh;
}

.container-alicia-pq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.pq {
  width: 17em;
  height: 15em;
  background: #4b92fc;
  border-radius: 100%;
  padding: 36px 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;

  span {
    font-size: 2em;
    font-weight: bold;
  }
  p {
    color: #fff;
  }
}

.pq .triangulo-pq {
  width: 0;
  height: 0;
  border-left: 48px solid #4b92fc;
  border-top: 12px solid transparent;
  border-bottom: 47px solid transparent;
  position: relative;
  top: -5vh;
  left: 11vw;
  -webkit-transform: rotate(49deg);
  transform: rotate(49deg);
  z-index: -1;
}

.card {
  margin-bottom: 0px !important;
}

.text-content-modal {
  font-size: 1em;
}

@media only screen and (max-width: 575px) {
  .avatar__alicia-pq {
    height: 45vh;
    margin-top: 13vh;
  }

  .title__section {
    font-size: 0.8em;
  }

  .pq {
    width: 12em;
    height: 11em;
    padding: 1.2em 2em;
  }

  .pq p {
    font-size: 0.8em;
  }

  .pq span {
    font-size: 1.2em;
  }

  .pq .triangulo-pq {
    top: -3vh;
    left: 26vw;
  }

  .modal.show .modal-dialog {
    max-width: 80% !important;
  }

  .text-content-modal {
    font-size: 0.8em;
    text-align: justify;
  }

  .btn__modal {
    padding: 4px 8px;
    font-size: 0.8em;
  }
}
</style>

<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
    <div class="col-12 col-md-7">
      <CRow>
        <CCol col>
          <CCard>
            <CCardHeader>
              <strong>Estado devolución de dinero</strong>
            </CCardHeader>
            <CCardBody>
              <div class="form-group col-md-10">
                <label for="">Seleccione una suscripción</label>
                <select
                  class="form-control"
                  @change="changeSuscription($event)"
                >
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="(item, index) in suscripciones"
                    :value="index + 1"
                    :key="item.id"
                  >
                    {{ item.requests }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-10">
                <label for="">Seleccione una solicitud</label>
                <select
                  class="form-control"
                  @change="detalleSolicitudes($event)"
                >
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="(item, index) in arrListDevoluciones"
                    :value="index + 1"
                    :key="item.id"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <CCard>
                <CCardHeader>
                  {{ nameSuscription2 }}
                  <div class="card-header-actions">
                    <a
                      href="https://coreui.io/vue/docs/components/card-components"
                      class="card-header-action"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                    </a>
                  </div>
                </CCardHeader>
                <CCardBody class="p-4">
                  <div
                    v-if="suscriptionSelected.name"
                    class="col-md-10 container__detalle"
                  >
                    <p
                      v-if="
                        suscriptionSelected.monto != '' &&
                        suscriptionSelected.monto != null &&
                        suscriptionSelected.monto != undefined
                      "
                    >
                      <strong>Importe aproximado: </strong
                      >{{ suscriptionSelected.monto }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.banco != '' &&
                        suscriptionSelected.banco != null &&
                        suscriptionSelected.banco != undefined
                      "
                    >
                      <strong>Banco: </strong>{{ suscriptionSelected.banco }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.nroCuenta != '' &&
                        suscriptionSelected.nroCuenta != null &&
                        suscriptionSelected.nroCuenta != undefined
                      "
                    >
                      <strong>Nro. Cuenta: </strong
                      >{{ suscriptionSelected.nroCuenta }}
                    </p>
                    <p
                      v-if="
                        suscriptionSelected.message != '' &&
                        suscriptionSelected.message != null &&
                        suscriptionSelected.message != undefined
                      "
                    >
                      <strong>Detalle : </strong
                      >{{ mensajeDetalle }}
                    </p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>

    <div class="col-12 col-md-5 d-flex justify-content-center align-items-center">
      <img
        class="avatar__alicia-pq"
        v-if="flagImg == 1"
        src="../../assets/img/nueva_alicia_pages_all.png"
      />
      <!--<img class="avatar__alicia__mobile-pq" src="../../assets/img/alicia_3.jpg" />-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una Solicitud",
      nameSuscription2: "",
      suscriptionSelected: {},
      arrListDevoluciones: [],
      arrOpcion: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Si actualizar mi correo de acceso" },
        { id: "2", name: "No conservar mi correo de acceso" },
      ],
      show: true,
      firstName: localStorage.getItem("name").split(" ")[0],
      isLoading: false,
      isNewCorreoPeruQuiosco: false,
      isObservacion: false,
      NewCorreoPeruQuiosco: "",
      observacion: "",
      positionId: "",
      positionIdSolicitud: "",
      emailVerificado: "",
      opcionCambiar: "",
      fullPage: true,
      flagImg: 1,
      mensajeDetalle:"",
    };
  },
  components: {
    Loading,
  },
  methods: {
    listSubscriptions() {
      let vm = this;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      //vm.nameSuscription = "Aún no ha seleccionado una solicitud";

      let data = {
        document_number: localStorage.getItem("numeroDocumento")  /*localStorage.getItem("numeroDocumento")  08350853 43548833*/,
        document_type: localStorage.getItem("tipoDocumento"),
        channel: "1",
      };
      //console.log("Send Data =>", data);
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/devolucion-dinero/listar`, data)
        .then(function (res) {
          vm.isLoading = false;
          console.log("Response =>", res);
          if (res.data.data.code == 1) {
            vm.isLoading = false;
            vm.suscripciones = [res.data.data];
            vm.$fire({
              title: "Info",
              text: `${res.data.data.name}, ${res.data.data.message}`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
          } else {
            vm.isLoading = false;
            vm.$fire({
              title: "Info",
              text: "No tiene suscripcion con solicitud de devolución",
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.suscripciones = [];
          }
        })
        .catch((err) => {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: "Ocurrio un error en el servidor",
            type: "error",
            timer: 3000,
          }).then((r) => {});
        });
    },
    async changeSuscription(event) {
      let vm = this;
      vm.positionId = event.target.value;
      vm.isLoading = true;

      //vm.nameSuscription = "Aún no ha seleccionado una solicitud";

      if (vm.positionId == "") {
        vm.isLoading = false;
      } else {
        vm.isLoading = false;
        console.log(vm.positionId);

        let data = {
          positionId: vm.positionId,
          document_number:localStorage.getItem("numeroDocumento") /* localStorage.getItem("numeroDocumento") 08350853 43548833*/,
          document_type: localStorage.getItem("tipoDocumento"),
          channel: "4",
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/devolucionesList`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        console.log("response", response);

        if (response.code == 1) {
          vm.isLoading = false;

          let arrDev = response.requests;
          arrDev = arrDev.split("\n");
          arrDev.pop();
          vm.arrListDevoluciones = arrDev;
          vm.$fire({
            title: "Atención",
            text: `${response.name}, ${response.message}`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          //vm.nameSuscription = `Encontré las siguientes solicitudes de devolución en la suscripción elegida.`;
        }
      }
    },
    async detalleSolicitudes(event) {
      let vm = this;
      vm.positionIdSolicitud = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};

      if (vm.positionIdSolicitud == "") {
        vm.isLoading = false;
      } else {
        vm.isLoading = false;

        let data = {
          positionIdPrevious: vm.positionId,
          document_number:localStorage.getItem("numeroDocumento")  /* localStorage.getItem("numeroDocumento") Documentos de prueba 43548833   08350853*/,
          document_type: localStorage.getItem("tipoDocumento"),
          positionId: vm.positionIdSolicitud,
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/consultaDevolucion`, data)
          .then((res) => {
            console.log("response", res);
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        if (response.code == 3) {
          vm.isLoading = false;
          vm.suscriptionSelected = response;
          vm.mensajeDetalle = "La devolución de dinero fue realizada con éxito.";
          vm.nameSuscription2 = "Detalle de la solicitud";
        } else if (response.code == 2) {
          vm.isLoading = false;
          vm.suscriptionSelected = response;
          vm.mensajeDetalle = "La devolución de dinero se encuentra en proceso.";
          vm.nameSuscription2 = "Detalle de la solicitud";
        } else {
          vm.isLoading = false;
          vm.nameSuscription2 = ` ${response.name}, verifico que la solicitud de devolución de dinero fue rechazada. Te recomendamos contactarnos vía Whatsapp al 955 559 683 o a través de clubelcomercio.pe`;
        }
      }
    },
    showImg() {
      this.flagImg = 1;
    },
    hideImg() {
      this.flagImg = 0;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    vm.listSubscriptions();
  },
};
</script>
